body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"!important;
    font-size: 1rem!important;
    font-weight: 400!important;
    line-height: 1.5!important;
    -webkit-font-smoothing: auto;
}

.fade.in {
    opacity: 1!important;
}

.border_error,
.border_error>a {
    border-color: red!important;
}


/*MainPage*/

.position-center,
#NoPageFound,
#title {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
}

.MainPage {
    height: 100vh;
    overflow: hidden;
    color: #fff;
}

.MainPage a,
.MainPage a:hover {
    color: #fff;
    text-decoration: none;
}

#background {
    width: 100%;
    height: 100vh;
}

.fixed,
#title,
#NoPageFound {
    position: fixed;
}

#title {
    font-size: 8vw;
}

.cursor {
    margin: 1.5rem 1rem 0 1rem;
}

.cursor.rotate {
    transform: scaleX(-1);
}

.address-information,
.phone-information {
    font-size: x-large;
    width: 50%;
}

.address-information {
    position: fixed;
    top: 1rem;
    right: 2rem;
    text-align: right;
}

.phone-information {
    position: fixed;
    bottom: 1rem;
    left: 2rem;
}


/*Animation*/

.slide-enter,
.slide-exit {
    transition: all 0.5s ease;
}

.slide-enter {
    opacity: 0;
    transform: translateX(100%);
}

.slide-enter.slide-enter-active {
    opacity: 1;
    transform: translateX(0%);
}

.slide-exit {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transform: translateX(0%);
}

.slide-exit-active {
    transform: translateX(-100%);
}


/*Nav*/

.Prestation nav {
    display: grid;
    grid-template-columns: 10% calc(90% - 324px) 324px;
    align-items: center;
    margin-bottom: 60px;
}

.Prestation nav *,
.prestation-link .devis_link {
    transition: color ease 1s;
}

.Prestation nav * {
    grid-row: 1;
}

.Prestation nav a,
.Prestation nav a:hover {
    color: #fff;
    text-decoration: none;
}

.Prestation nav input {
    height: 24px;
    width: 300px;
    border: none;
    grid-column: 3;
}

.Depannage nav input,
.Depannage nav input:focus {
    background: rgb(171, 204, 187);
    transition: all ease 0.5s;
}

.form-control {
    height: auto;
}

.search-item-bar {
    position: absolute;
    background: rgb(93, 161, 169);
    border-radius: 0.5em;
    padding: 12px;
    z-index: 1000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.06), 0 4px 4px rgba(0, 0, 0, 0.12);
}

.search-item-bar.rdv {
    background: #fff;
}

.search-item {
    cursor: pointer;
}


/*Page*/

.Prestation {
    color: #fff;
    padding: 30px 60px;
    min-height: 100vh;
}

.Depannage {
    background: linear-gradient(45deg, rgb(67, 147, 163) 0%, rgb(67, 147, 163) 59%, rgb(93, 161, 169) 59%, rgb(93, 161, 169) 65%, rgb(119, 175, 175) 65%, rgb(119, 175, 175) 70%, rgb(145, 190, 181) 70%, rgb(145, 190, 181) 73%, rgb(171, 204, 187) 73%, rgb(171, 204, 187) 78%, rgb(197, 218, 193) 78%, rgb(197, 218, 193) 100%);
}

.Jardinage {
    background: linear-gradient(45deg, #43A047 0%, #43A047 59%, #66BB6A 59%, #66BB6A 65%, #81C784 65%, #81C784 70%, #A5D6A7 70%, #A5D6A7 73%, #C8E6C9 73%, #C8E6C9 78%, #E8F5E9 78%, #E8F5E9 100%);
}


/*Display flex*/

.container-card,
.material-card,
.material-card-body {
    display: flex;
}

.material-card,
.material-card-body {
    flex-direction: column;
}

.material-card {
    background: #fff;
    border-radius: 1em;
    padding: 15px 30px;
    color: black;
    margin: 40px 5%;
    box-shadow: 0px 10px 30px -10px rgba(151, 163, 184, 0.5);
    transition: box-shadow ease 0.5s;
    transition: transform ease 1s;
}

.material-card:hover {
    transform: translate(0, -10px);
    box-shadow: 0px 10px 0px -10px rgba(151, 163, 184, 0.5), 0px 20px 10px -20px rgba(151, 163, 184, 0.5), 0px 30px 20px -25px rgba(151, 163, 184, 0.5), 0px 40px 30px -30px rgba(151, 163, 184, 0.5), 0px 50px 50px -35px rgba(151, 163, 184, 0.5);
}

.material-card-header img {
    float: left;
    margin-left: -80px;
    margin-top: -70px;
    width: 120px;
}

.material-card-header h4 {
    margin-top: 10px;
}

.material-card-body {
    align-items: center;
    height: 100%;
}

.material-card-body p {
    margin: auto 0;
}

.devis_link,
.devis_link:hover {
    color: #FFEBCF;
    text-decoration: underline;
}

.rs-drawer-content {
    border-radius: 1.5em 0 0 1.5em;
}

.rs-drawer-backdrop.in {
    opacity: .3!important;
}

.rs-drawer-title {
    font-size: 22px!important;
}

.rs-drawer-body {
    padding-right: 15px;
}

.rs-drawer-body::-webkit-scrollbar {
    -webkit-appearance: none;
}

.rs-drawer-body::-webkit-scrollbar:vertical {
    width: 5px;
}

.rs-drawer-body::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, .5);
}


/*Rendez-vous*/

.form-panel {
    align-items: center;
    margin-bottom: 1em;
}

.form-panel>span {
    display: flex;
}

.verification {
    position: fixed;
    opacity: 1;
    z-index: 100;
    max-width: 90vw;
}

.toast {
    backdrop-filter: initial
}

#display_renseignement,
#display_prestation {
    margin-top: -21px;
}

#display_renseignement {
    margin-left: 8.5em
}

#display_prestation {
    margin-left: 7em
}

.emailExist {
    color: #1675e0;
    cursor: pointer;
    display: inline-block;
}

.rs-tag-text {
    font-size: initial;
}

#outils,
#computer,
#jardinage,
#informatique {
    transition: all ease 1s;
}


/*Ordinateur*/

@media (min-width: 993px) {
    #outils,
    #computer {
        width: 37vw;
    }
    #outils:hover,
    #computer:hover {
        width: 38vw;
    }
    #outils {
        top: -225px;
        left: -135px;
    }
    #computer {
        right: -100px;
        bottom: -190px;
    }
    #jardinage,
    #informatique {
        font-size: 3vw;
    }
    #jardinage:hover,
    #informatique:hover {
        font-size: 3.5vw;
    }
    #jardinage {
        left: 31%;
        top: 12%;
    }
    #informatique {
        right: 33%;
        bottom: 12%;
    }
    .container-card {
        flex-direction: row;
    }
    .material-card {
        width: 25%;
    }
    .mr-00 {
        margin-right: 0!important;
    }
    .ml-00 {
        margin-left: 0!important;
    }
    .form-panel {
        display: flex;
    }
    .form-panel div {
        margin: 0 2em;
    }
    .form-panel>div:first-child,
    .form-panel span:first-child>div:first-child {
        margin-left: 0;
    }
    .adress {
        width: 33%;
    }
}


/*Portable*/

@media (max-width: 992px) {
    #outils,
    #computer {
        width: 50vw;
    }
    #outils:hover,
    #computer:hover {
        width: 52vw;
    }
    #outils {
        top: -50px;
        left: -50px;
    }
    #computer {
        bottom: -50px;
        right: -50px;
    }
    #jardinage,
    #informatique {
        font-size: 3vh;
    }
    #jardinage:hover,
    #informatique:hover {
        font-size: 3.5vh;
    }
    #jardinage {
        right: 25vw;
        top: 22vh;
    }
    #informatique {
        left: 20vw;
        bottom: 22vh;
    }
    .Depannage nav input::placeholder {
        color: #fff;
    }
    .Depannage nav input,
    .Depannage nav input:focus {
        color: #fff;
        place-content: #fff;
        background: rgb(67, 147, 163);
    }
    .Jardinage nav *,
    .prestation-link {
        color: black!important;
    }
    .prestation-link>.devis_link,
    .devis_link:hover {
        color: #2962FF
    }
    .DepannagePage {
        padding-top: 30px;
    }
    .Prestation nav input {
        width: calc(100% - 176px);
    }
    .container-card {
        flex-direction: column;
    }
    .material-card {
        width: 100%;
        margin: 40px 0;
    }
    .rs-drawer {
        width: 300px!important;
    }
    .form-panel>span {
        width: -webkit-fill-available;
    }
    .form-panel span>div {
        width: inherit;
    }
    .form-panel span>div:first-child {
        margin-right: 1em;
    }
    .form-panel span>div:last-child {
        margin-left: 1em;
    }
    .form-panel>span,
    .form-panel>div:first-child {
        margin-bottom: 1em;
    }
    .verification {
        min-width: 90vw;
    }
}

@media (max-width: 1250px) {
    .Prestation nav {
        display: block;
    }
    .Prestation nav h4 {
        margin: 0 3em 15px 0;
        display: inline-block;
    }
    .Prestation nav input {
        display: inline-block;
    }
}